const router = [
  {
    path: "/",
    component: () => import("@/components/Sales-003"),
  },
  {
    path: "/Sales-001",
    component: () => import("@/components/Sales-001"),
  },
  {
    path: "/Sales-002",
    component: () => import("@/components/Sales-002"),
  },
  {
    path: "/OriginalDesign",
    component: () => import("@/components/OriginalDesign"),
  },
  {
    path: "/TOS",
    component: () => import("@/components/TOS"),
  },
  { 
	path: '/:pathMatch(.*)',
	component: () => import("@/components/Sales-003")
  }
];
export default router