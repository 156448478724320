import { createRouter, createWebHistory } from "vue-router";

import routes from "./router";

const scrollBehavior = (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }

const router = createRouter({
    /*
     * NOTE! Change Vue Router mode from quasar.conf.js -> build -> vueRouterMode
     *
     * When going with "history" mode, please also make sure "build.publicPath"
     * is set to something other than an empty string.
     * Example: '/' instead of ''
     */

    // Leave as is and change from quasar.conf.js instead!
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
    scrollBehavior,
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
