<template>
    <q-layout view="lHh LpR lff" container style="height: 100vh" class="shadow-2 rounded-borders">
      <q-header reveal class="bg-black">
        <q-toolbar>
          <div class="absolute-center" style="min-width: 350px">全てのシェルティ好きの皆様へ、商品のご案内です。</div>
          <q-btn flat round dense to="/">
            <q-icon name="home" />
          </q-btn>
          <q-toolbar-title></q-toolbar-title>
          <q-btn flat round dense to="/TOS">
            <q-icon name="help_outline" />
          </q-btn>
        </q-toolbar>
      </q-header>

      <q-footer class="bg-black">
        <q-toolbar>
          <div class="absolute-center text-caption" style="min-width: 400px; ">DogsMagic（dogsmagic.jp / dogsmagic.store）コンテンツまたはその一部を、許可なく転載することを禁止します。 Reproducing all or any part of the contents is prohibited. © 2022</div>
        </q-toolbar>
      </q-footer>

      <q-page-container>
        <q-page style="padding-top: 60px" class="q-pa-md">
          <router-view />
        </q-page>

<!--         <q-page-scroller position="bottom"> -->
<!--           <q-btn fab icon="keyboard_arrow_up" color="red" /> -->
<!--         </q-page-scroller> -->
      </q-page-container>
    </q-layout>
</template>

<script>
export default {
  setup () {
    return {
    }
  }
}
</script>
