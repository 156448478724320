import { createApp } from 'vue'
import App from './App.vue'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import router from "./router";

const app = createApp(App);

app.use(Quasar);
app.use(quasarUserOptions);
app.use(router);
app.mount('#app');
// 個別利用しないと、ルーターが使えない createApp(App).use(Quasar, quasarUserOptions, router).mount('#app')
